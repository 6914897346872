$(function(){
    $(".sendStoppedMonthlyPaymentReminder").on("click", function(e){
        e.preventDefault();
        //$(this).prop('disabled', true);
        var that = $(this);
        var memberId = $(this).data('member-id');
        //disable button
        $.ajax({
            // url: '{{ route('sendStoppedMonthlyPaymentReminderApi') }}' + '?memberId=' + memberId + '&_token=' + $('input[name="_token"]').attr('value'),
            url: '/api/v1/subscriptions/sendStoppedMonthlyPaymentReminder' + '?memberId=' + memberId + '&_token=' + $('input[name="_token"]').attr('value'),
            type: 'post',
            success: function() {
                that.text('Approved');

            }, error: function(){
                //that.prop('disabled', false);
                alert('failed');
            }
        })
    })
});

$(function(){
    $(".sendStoppedPaymentReminder").on("click", function(e){
        e.preventDefault();
        $(this).prop('disabled', true);
        var that = $(this);
        var memberid = $(this).data('memberid');
        var tr = $(this).parents('tr');
        //disable button
        $.ajax({
            // url: '{{ route('sendStoppedPaymentReminderApi') }}' + '?memberId=' + memberid + '&_token=' + $('input[name="_token"]').attr('value'),
            url: '/api/v1/membership/sendStoppedPaymentReminder' + '?memberId=' + memberid + '&_token=' + $('input[name="_token"]').attr('value'),
            type: 'post',
            success: function() {

            }, error: function(){
                that.prop('disabled', false);
                alert('failed');
            }
        })
    })
});

$(function(){
    $(".lapsedMembershipReminder").on("click", function(e){
        e.preventDefault();
        $(this).prop('disabled', true);
        var that = $(this);
        var memberid = $(this).data('member-id');
        var tr = $(this).parents('tr');
        //disable button
        $.ajax({
            // url: '{{ route('sendLapsedMembershipReminderApi') }}' + '?memberId=' + memberid + '&_token=' + $('input[name="_token"]').attr('value'),
            url: '/api/v1/membership/sendLapsedMembershipReminder' + '?memberId=' + memberid + '&_token=' + $('input[name="_token"]').attr('value'),            type: 'post',
            success: function() {

            }, error: function(){
                that.prop('disabled', false);
                alert('failed');
            }
        })
    })
});

$(function(){
    $(".sendPaymentReminder").on("click", function(e){
        e.preventDefault();
        $(this).prop('disabled', true);
        var that = $(this);
        var newMemberId = $(this).data('new-member-id');
        //disable button
        $.ajax({
            // url: '{{ route('sendFirstPaymentReminder') }}' + '?new-member-id=' + newMemberId + '&_token=' + $('input[name="_token"]').attr('value'),
            url: '/api/v1/membership/sendFirstPaymentReminder' + '?new-member-id=' + newMemberId + '&_token=' + $('input[name="_token"]').attr('value'),
            type: 'post',
            success: function() {
                that.text('Reminder sent');
                alert('worked');
            }, error: function(){
                that.prop('disabled', false);
                alert('failed');
            },
        })
    })
});

var paymentSearch = function() {
    $(".payment-search").each(function() {
        $(this).select2({
            minimumInputLength: 1,
            allowClear: true,
            tags: true,                    

            ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
                // url: "{{ route('paymentSearch') }}",
                url: "/api/v1/search/payment",

                dataType: 'jsonp',
                data: function (params, page) {
                    return {
                        q: params.term
                    };
                },
                processResults: function (data, page) {
                    return {results: data.paymentItem};
                    //console.log(data.members);
                }
            },

            templateResult: function (data) {
                return data.description;
            },
            templateSelection: function (data) {
                return data.description;
            }

        });
    });
}

$(function(){
    $(".launchAddPayment").on("click", function(e){
        e.preventDefault();
        //$(this).prop('disabled', true);
        var that = $(this);
        var memberId = $(this).data('member-id');
        //disable button
        var url = '/payments/subscriptions/create/' + memberId;
        //var url = '{{ route('createSubscription') }}' + '/' + memberId;
        window.open(url, '_blank');

    })
}); 