$(".member-search").each(function() {
    $(this).select2({
        minimumInputLength: 1,
        allowClear: true,
        tags: true,                                                    

        ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
            url: "/api/v1/search/member",
            dataType: 'jsonp',
            data: function (params, page) {
                return {
                    q: params.term
                };
            },
            processResults: function (data, page) {
                return {results: data.members};
                //console.log(data.members);
            }
        },

        templateResult: function (data) {
            return data.name;
        },
        templateSelection: function (data) {
            return data.name;
        }

    });
});

$(".confirm-new-member").each(function() {
    $(this).select2({
        minimumInputLength: 1,
        allowClear: true,
        tags: true,                    

        ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
            // url: "{{ route('paymentSearch') }}",
            url: "/api/v1/search/payment",

            dataType: 'jsonp',
            data: function (params, page) {
                return {
                    q: params.term
                };
            },
            processResults: function (data, page) {
                return {results: data.paymentItem};
                //console.log(data.members);
            }
        },

        templateResult: function (data) {
            return data.description;
        },
        templateSelection: function (data) {
            return data.description;
        }

    });
});

$(".member-info").each(function() {
    $(this).select2({
        minimumInputLength: 1,

        ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
            // url: "{{ route('memberInfo') }}",
            url: "/api/v1/membership/memberInfo",

            dataType: 'jsonp',
            type: 'post',
            data: function (term, page) {
                return {
                    q: term
                };
            },
            results: function (data, page) {
                return {results: data.memberInfo};
                //console.log(data.members);
            }
        },

        formatResult: function (data) {

            index = this.element.data('index');
            return "<div class='select2-user-result'>" + data.firstName + " " + data.lastName + "</div>";
        },
        formatSelection: function (data) {
            $("#\\[" + index + "\\]\\[first-name\\]").text(data.firstName);
            $("#\\[" + index + "\\]\\[last-name\\]").text(data.lastName);
            $("#\\[" + index + "\\]\\[membership-category\\]").text(data.membershipCategory);
            $("#\\[" + index + "\\]\\[membership-status\\]").text(data.membershipStatus);
            $("#\\[" + index + "\\]\\[last-payment-date\\]").text(data.lastPaymentDate);
            $("#\\[" + index + "\\]\\[last-payment-amount\\]").text(data.lastPaymentAmount);
            return data.firstName;
        }

    });
});
