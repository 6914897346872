$(function(){
    $(".approveNewMember").on("click", function(e){
        e.preventDefault();
        $(this).prop('disabled', true);
        var that = $(this);
        var newMemberId = $(this).data('new-member-id');
        var tr = $(this).parents('tr');
        var route = _.find(JSON.parse($('meta[name="_routes"]').attr('content')), function(route) { return 'approveNewMemberApi' in route})
        //disable button
        $.ajax({
            url: route.approveNewMemberApi + '?newMemberId=' + newMemberId + '&_token=' + $('input[name="_token"]').attr('value'),
            type: 'post',
            success: function() {
                that.text('Approved');
                tr.fadeOut(800, function() { $(this).remove(); });

            }, error: function(){
                that.prop('disabled', false);
                alert('failed');
            }
        })
    })
});

var newMemberSearch = function() {
    $(".new-member-search").each(function() {
        $(this).select2({
            minimumInputLength: 1,
            allowClear: true,
            tags: true,
            placeholder: function(){
                return {
                    id: "Start typing to search for members",
                    text: "Start typing to search for members",
                    membershipStatus: ''
                }
            },
            createTag: function (tag) {
                return {
                    id: tag.term,
                    text: tag.term,
                    firstName: tag.firstName,
                    lastName: tag.lastName,
                    membershipStatus: tag.membershipStatus,
                    memberNumber: tag.memberNumber,

                    tag: true
                };
            },
            ajax: {
                url: "/api/v1/search/newAttendee",
                dataType: 'jsonp',
                data: function (params, page) {
                    return {
                        q: params.term
                    };
                },
                processResults: function (data, page) {
                    return { results: data.members };
                }
            },
            templateResult: function (data) {
                return  data.name;
            },
            templateSelection: function (data) {
                if(data.membershipStatus == 'unknown') data.id = '';
                return data.name;
            }
        }); 
    });
}   

Vue.directive('payment-search', {
    twoWay: true,
    deep: true,
    bind: function() {
        var self = this
        var vm = this.vm;
        var key = this.expression;

        var select = $(this.el);

        $(this.el).select2({
            minimumInputLength: 1,
            allowClear: true,
            tags: true,                    

            ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
                // url: "{{ route('paymentSearch') }}",
                url: "/api/v1/search/payment",

                dataType: 'jsonp',
                data: function (params, page) {
                    return {
                        q: params.term
                    };
                },
                processResults: function (data, page) {
                    return {results: data.paymentItem};
                    //console.log(data.members);
                }
            },

            templateResult: function (data) {
                return data.description;
            },
            templateSelection: function (data) {
                return data.description;
            }

        })
        .on('change', function() {
            index = self.vm.$index
            var newMember  = self.vm.$data.newMember
            newMember.$set('paymentId', this.value)
            self.vm.newMembers.$set(index,newMember)
        })
    },
})

Vue.directive('new-attendee-search', {
    twoWay: true,
    bind: function() {
        var self = this

        $(this.el).select2({
            minimumInputLength: 1,
            allowClear: true,
            tags: true,
            createTag: function (tag) {
                return {
                    id: tag.term,
                    text: tag.term,
                    firstName: tag.firstName,
                    lastName: tag.lastName,
                    memberNumber: tag.memberNumber,

                    tag: true
                };
            },
            ajax: {
                url: "/api/v1/search/newAttendee",
                dataType: 'jsonp',
                data: function (params, page) {
                    return {
                        q: params.term
                    };
                },
                processResults: function (data, page) {
                    return { results: data.members };
                }
            },
            templateResult: function (data) {
                return  data.name;
            },
            templateSelection: function (data) {
                return data.name;
            }
        })
        .on('change', function() {
            index = self.vm.$index
            var newMember  = self.vm.$data.newMember
            self.vm.$data.newMember.$set('newAttendeeId', this.value)
            self.vm.newMembers.$set(index,newMember)
        })

    },
    update: function() {

    }
})


new Vue({
    el: '#verify-new-members',
    created: function () {
        this.fetchData()
        this.$watch('currentBranch', function () {
          this.fetchData()
        })
    },
    ready: function(){
        this.fetchData();
    },
    data: {
        newMembers: [],
    },
    methods: {
        updateNewMember: function(newMember1) {
            var index = _.findIndex(this.newMembers, function(newMember) { return newMember.id == newMember1.id }    )
            //this.newMembers[index] = newMember1
            this.newMembers.$set(index, newMember1);
        },    
        fetchData: function() {
            this.$http.get('/api/v1/membership/newMembersNotMatchedToNewAttendee', function (data, status, request) {

                this.newMembers = data.newMembers;
                newAttendeeSearch();

            });
        },
        initialiseCloseEvents: function() {
            var e = document.createEvent('HTMLEvents');
            e.initEvent('change', true, true);

            $(this.$el).find('.new-attendee-search')
                .on('select2:close', function() {
                    this.dispatchEvent(e);
                });

            $(this.$el).find('.payment-search')
                .on('select2:close', function() {
                    this.dispatchEvent(e);
                });
        },
		update: function() {
			
            //the new members who have a corresponding new attendee
			var matchedWithPayment = [];
			var unmatchedWithPayment = [];
			
			_.each(this.newMembers, function(newMember) {
    			//only process those that have a paymentId
                if (typeof newMember.paymentId !== "undefined" && newMember.paymentId != '')
                {
                	if(typeof  newMember.newAttendeeId === "undefined" || newMember.newAttendeeId === '')
    				{
                        unmatchedWithPayment.push({
                            newMemberId : newMember.id,
                            paymentId : newMember.paymentId
                        });
    				}
    				else
    				{
                        matchedWithPayment.push({
                            newMemberId : newMember.id,
                            // memberNumber : newMember.memberNumber,
                            newAttendeeId : newMember.newAttendeeId,
                            paymentId : newMember.paymentId
                        });
    				}
                }
			})

			var postData = { 
				matchedWithPayment : matchedWithPayment, 
				unmatchedWithPayment : unmatchedWithPayment 
			}

			this.$http.post('/api/v1/membership/matchNewMembersToNewAttendees', postData, function (data, status, request) {

	        }).error(function(data, status, request){

        	});
		},
    	parseNewAttendeeSearchSelection: function(e) {
       		var data  = $(this.$el).find('.new-attendee-search').select2('data');
       		var index = _.findIndex(this.newMembers, function(newMember) { return newMember.id == e.id } 	) 

            if(data.length > 0)
            {
           		e.memberNumber = data[0].memberNumber;
           		e.newAttendeeId = data[0].id;
           		this.newMembers.$set(index, e);
           	}
		},
        parseNewMemberSearchSelection: function(e) {
            var index = _.findIndex(this.newMembers, function(newMember) { return newMember.id == e.id }    )
            var data  = $(this.$el).find('.payment-search')[index].select2('data');

            // if(data.length > 0)
            // {
            //     e.paymentId = data[0].id;
            //     // e.newAttendeeId = data[0].id;
            //     this.newMembers.$set(index, e);
            // }
            e.paymentId = 1;
            this.newMembers.$set(index, e);
        },
    }
})