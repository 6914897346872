$(function(){
    $('.subscriptionsRevealModal').on('click', function(e) {
        e.preventDefault();
        $('.subscription-items tbody').empty();
        var memberId = $(this).data('member-id');
        var subscriptionPeriodId = $(this).data('subscription-period-id');

        var request = $.ajax({
            // url: '{{ route('subscriptionItemsApi') }}',
            url: '/api/v1/subscriptions/subscriptionItems',
            data: { memberId: memberId,
                    subscriptionPeriodId: subscriptionPeriodId,
                _token: $('input[name="_token"]').attr('value')},
            type: 'post'
        });

        request.done(function( data ) {
            //alert(data[0].amount);
            $.each( data, function( key, subscriptionItem ) {

                $('.subscription-items tbody').append('<tr><td>'+subscriptionItem.date+'</td><td>'+subscriptionItem.description+'</td><td>'+subscriptionItem.amount+'</td></tr>');
            });


            $('#subscriptionsModal').foundation('reveal', 'open');
        });

        request.fail(function( jqXHR, textStatus ) {
                alert( "Request failed: " + textStatus );
        });
    });
});

$("#member-type").change(function () {
    setRow();
});

$("#membership-category").change(function () {
    setRow();
});

$("#claim").change(function () {
    setRow();
});
               

$(function(){
    $(".updateEaNumber").on("click", function(e){
        e.preventDefault();
        $(this).prop('disabled', true);
        var that = $(this);
        var memberId = $(this).data('member-id');
        var eaNumber = $(this).parents('tr').find('#ea-number').val();
        //disable button
        $.ajax({
            url: '/api/v1/membership/updateEAnumber' + '?memberId=' + memberId + '&eaNumber=' + eaNumber + '&_token=' + $('input[name="_token"]').attr('value'),
            // url: '{{ route('updateEaNumberApi') }}' + '?memberId=' + memberId + '&eaNumber=' + eaNumber + '&_token=' + $('input[name="_token"]').attr('value'),
            type: 'post',
            success: function() {
                that.text('Approved');
                //that.prop('disabled', false);
                //tr.fadeOut(800, function() { $(this).remove(); });

            }, error: function(){
                that.prop('disabled', false);
                alert('failed');
            }
        })
    })
});












// $(".member-search").each(function() {
//     $(this).select2({
//         minimumInputLength: 1,

//         ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
//             // url: "{{ route('memberSearchApi') }}",

//             dataType: 'jsonp',
//             data: function (term, page) {
//                 return {
//                     q: term
//                 };
//             },
//             results: function (data, page) {
//                 return {results: data.members};
//                 //console.log(data.members);
//             }
//         },

//         formatResult: function (data) {
//             return "<div class='select2-user-result'>" + data.name + "</div>";
//         },
//         formatSelection: function (data) {
//             return data.name;
//         }

//     });
// });



//
//                table.bind('aftertablesort', function (event, data) {
//                    // data.column - the index of the column sorted after a click
//                    // data.direction - the sorting direction (either asc or desc)
//
//                    var th = $(this).find("th");
//                    th.find(".arrow").remove();
//                    var arrow = data.direction === "asc" ? "&uarr;" : "&darr;";
//                    th.eq(data.column).append('<span class="arrow">' + arrow +'</span>');
//                });

// var memberSearch = function() {
    
    // $(".new-member-search").each(function() {
    //     $(this).select2({
    //         // minimumInputLength: 1,

    //         ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
    //             url: "{{ route('memberSearchApi') }}",

    //             dataType: 'jsonp',
    //             data: function (term, page) {
    //                 return {
    //                     q: term
    //                 };
    //             },
    //             results: function (data, page) {
    //                 return {results: data.members};
    //                 //console.log(data.members);
    //             }
    //         },

    //         // formatResult: function (data) {
    //         //     return "<div class='select2-user-result'>" + data.name + "</div>";
    //         // },
    //         // formatSelection: function (data) {
    //         //     return data.name;
    //         // }

    //     });
    // });




// $(".new-attendee-search").each(function() {
//                     $(this).select2({
//                         minimumInputLength: 1,
//                         allowClear: true,
//                         tags: true,
//                         placeholder: function(){
//                             return {
//                                 id: "Start typing to search for members",
//                                 text: "Start typing to search for members",
//                                 membershipStatus: ''
//                             }
//                         },
//                         createTag: function (tag) {
//                             return {
//                                 id: tag.term,
//                                 text: tag.term,
//                                 firstName: tag.firstName,
//                                 lastName: tag.lastName,
//                                 memberNumber: tag.memberNumber,

//                                 tag: true
//                             };
//                         },
//                         ajax: {
//                             url: "/api/v1/search/newAttendee",
//                             dataType: 'jsonp',
//                             data: function (params, page) {
//                                 return {
//                                     q: params.term
//                                 };
//                             },
//                             processResults: function (data, page) {
//                                 return { results: data.members };
//                             }
//                         },
//                         templateResult: function (data) {
//                             return  data.name;
//                         },
//                         templateSelection: function (data) {
//                             return data.name;
//                         }
//                     }); 
//                 });


function setupDaysDropdown(item) {
    var days = _.range(1, 31 + 1);
    option = '';

    for (i = 0; i < days.length; i++) {
        if (item.data('value') == i + 1) {
            option += '<option value="' + days[i] + '"  selected>' + days[i] + '</option>';
        }
        else {
            option += '<option value="' + days[i] + '">' + days[i] + '</option>';
        }
    }
    $(item).append(option);
}

function setupMonthsDropdown(item) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    option = '';

    for (i = 0; i < months.length; i++) {

        monthVal = parseInt(i) + 1;

        if (item.data('value') == i + 1) {
            option += '<option value="' + monthVal + '"  selected>' + months[i] + '</option>';
        }
        else {
            option += '<option value="' + monthVal + '">' + months[i] + '</option>';
        }
    }
    $(item).append(option);
}

function setupYearsDropdown(item) {
    if (item.data('min')) {
        minYear = item.data('min');
    }
    else {
        minYear = 1920;
    }

    if (item.data('min')) {
        maxYear = item.data('max');
    }
    else {
        maxYear = 2016;
    }

    var years = _.range(minYear, maxYear + 1);
    option = '';

    for (i = 0; i < years.length; i++) {
        if (item.data('value') == years[i]) {
            option += '<option value="' + years[i] + '"  selected>' + years[i] + '</option>';
        }
        else {
            option += '<option value="' + years[i] + '">' + years[i] + '</option>';
        }
    }
    $(item).append(option);
}

function setRow() {
    resetMembershipTable()

    if ($("#member-type").val() != "" && $("#membership-category").val() != "" && $("#claim").val() != "") {

        if ($("#claim").val() == "2" || $("#claim").val() == "3") {

            $('#subscriptions-table-second-claim').addClass("highlight-table-row")
        }

        if ($("#claim").val() == "1") {
            if ($("#membership-category").val() == "1") {
                $('#subscriptions-table-clubhouse').addClass("highlight-table-row")
            }

            if ($("#membership-category").val() == "2") {
                $('#subscriptions-table-track').addClass("highlight-table-row")
            }

            if ($("#membership-category").val() == "3") {
                $('#subscriptions-table-unemployed').addClass("highlight-table-row")
            }

            if ($("#membership-category").val() == "4") {
                $('#subscriptions-table-student').addClass("highlight-table-row")
            }

            if ($("#membership-category").val() == "5") {
                $('#subscriptions-table-disabled').addClass("highlight-table-row")
            }

            if ($("#membership-category").val() == "6") {
                $('#subscriptions-table-over-60').addClass("highlight-table-row")
            }

            if ($("#membership-category").val() == "7") {
                $('#subscriptions-table-associate').addClass("highlight-table-row")
            }

        }

    }
}

function resetMembershipTable() {
    $('#subscriptions-table-clubhouse').removeClass("highlight-table-row")
    $('#subscriptions-table-track').removeClass("highlight-table-row")
    $('#subscriptions-table-unemployed').removeClass("highlight-table-row")
    $('#subscriptions-table-student').removeClass("highlight-table-row")
    $('#subscriptions-table-disabled').removeClass("highlight-table-row")
    $('#subscriptions-table-over-60').removeClass("highlight-table-row")
    $('#subscriptions-table-associate').removeClass("highlight-table-row")
    $('#subscriptions-table-second-claim').removeClass("highlight-table-row")
}

// var options = {
//       valueNames: [ 'first-name', 'last-name' ]
//     };

// var userList = new List('members', options);

$(document).ready(function() {

    // $(document).foundation();

    $('.date-day').each(function () {

        var itemId = $(this).attr('id');
        var item = $('#' + itemId);
        setupDaysDropdown(item);
    });

    $('.date-month').each(function () {

        var itemId = $(this).attr('id');
        var item = $('#' + itemId);
        setupMonthsDropdown(item);
    });

    $('.date-year').each(function () {

        var itemId = $(this).attr('id');
        var item = $('#' + itemId);
        setupYearsDropdown(item);
    });

    setRow();

    // $("table").stupidtable();
});
