var addNewAttendee = new Vue({
    el: '#create-new-attendee',
    data: {
        newAttendeeInfo: {
            firstName: null,
            lastName:null,
            email: null,
            emailParts: {
                prefix: null,
                domain: 'gmail.com'
            },
            firstAttendanceDate: null,
            firstAttendanceDateParts: {
                day:null,
                month: null,
                year: null
            }

        },
        errors: [],
        formData: {
            availableDays: null,
            availableMonths: null,
            availableYears: null,
        }
    },
    ready: function(){
        this.formData.availableDays = _.map(_.range(1, 32), function(val) {
            return { text: val.toString(), value: val.toString()}
        })  
        
        var availableMonths = function(){
            var m = moment();
            return _.map(_.range(1, 13), function(val) {
                return { 
                    text: m.month(val - 1).format('MMMM'), 
                    value: val.toString()}
            })  
        } 

        this.formData.availableMonths = availableMonths()

        this.formData.availableYears =  _.map(_.range(2014, 2017), function(val) {
            return { text: val.toString(), value: val.toString()}
        })
    },
    watch: {
        'newAttendeeInfo.firstName': function(val, oldVal)
        {
            var firstName = ! _.isEmpty(val) ? val.toLowerCase() : ''
            var lastName = ! _.isEmpty(this.newAttendeeInfo.lastName) ? this.newAttendeeInfo.lastName.toLowerCase() : ''
            this.newAttendeeInfo.emailParts.prefix = firstName + '.' + lastName
            this.newAttendeeInfo.email = this.newAttendeeInfo.emailParts.prefix + '@' + this.newAttendeeInfo.emailParts.domain
        },
        'newAttendeeInfo.lastName': function(val, oldVal)
        {
            var firstName = ! _.isEmpty(this.newAttendeeInfo.firstName) ? this.newAttendeeInfo.firstName.toLowerCase() : ''
            var lastName = ! _.isEmpty(val) ? val.toLowerCase() : ''
            this.newAttendeeInfo.emailParts.prefix = firstName + '.' + lastName
            this.newAttendeeInfo.email = this.newAttendeeInfo.emailParts.prefix + '@' + this.newAttendeeInfo.emailParts.domain
        },
        'newAttendeeInfo.emailParts.prefix': function(val, oldVal)
        {
            this.newAttendeeInfo.email = val + '@' + this.newAttendeeInfo.emailParts.domain
        },
        'newAttendeeInfo.emailParts.domain': function(val, oldVal)
        {
            this.newAttendeeInfo.email = this.newAttendeeInfo.emailParts.prefix + '@' + val
        },
        'newAttendeeInfo.firstAttendanceDateParts.day': function (val, oldVal) {
            this.newAttendeeInfo.firstAttendanceDate = this.createDate(val, this.newAttendeeInfo.firstAttendanceDateParts.month, this.newAttendeeInfo.firstAttendanceDateParts.year)
        },  
        'newAttendeeInfo.firstAttendanceDateParts.month': function (val, oldVal) {
            this.newAttendeeInfo.firstAttendanceDate = this.createDate(this.newAttendeeInfo.firstAttendanceDateParts.day, val, this.newAttendeeInfo.firstAttendanceDateParts.year)
        },
        'newAttendeeInfo.firstAttendanceDateParts.year': function (val, oldVal) {
            this.newAttendeeInfo.firstAttendanceDate = this.createDate(this.newAttendeeInfo.firstAttendanceDateParts.day, this.newAttendeeInfo.firstAttendanceDateParts.month, val)
        },            
    },
    methods: {
        createDate: function(day, month, year) {
            if(moment(year+" "+month+" "+day, "YYYY M D", true).isValid())
            {
                 return moment(year+" "+month+" "+day, "YYYY M D", true).format("YYYY-MM-DD")
            }

            return null
        },
        creatNewAttendee: function() {
            this.token = $('meta[name="csrf-token"]').attr('content');
                
            var route = $(this.$el).find('#_route-new-attendee-store').val();

            this.$http.post(route, this.mapPostData(this.newAttendeeInfo), function (data, status, request) {
                    // this.results = data.newAttendee;
                    this.resetNewAttendeeInfo()

                }).error(function(data, status, request){

            });   
        },
        mapPostData: function(postData) {
            return postData
        },
        resetNewAttendeeInfo: function() {
            this.newAttendeeInfo.firstName = null
            this.newAttendeeInfo.lastName = null
            this.newAttendeeInfo.emailParts.prefix = null
        }      
    }
})    


var newAttendeeSearch = function() {
    $(".new-attendee-search").each(function() {
        $(this).select2({
            minimumInputLength: 1,
            allowClear: true,
            tags: true,
            placeholder: function(){
                return {
                    id: "Start typing to search for members",
                    text: "Start typing to search for members",
                    membershipStatus: ''
                }
            },
            createTag: function (tag) {
                return {
                    id: tag.term,
                    text: tag.term,
                    firstName: tag.firstName,
                    lastName: tag.lastName,
                    memberNumber: tag.memberNumber,

                    tag: true
                };
            },
            ajax: {
                url: "/api/v1/search/newAttendee",
                dataType: 'jsonp',
                data: function (params, page) {
                    return {
                        q: params.term
                    };
                },
                processResults: function (data, page) {
                    return { results: data.members };
                }
            },
            templateResult: function (data) {
                return  data.name;
            },
            templateSelection: function (data) {
                return data.name;
            }
        }); 
    });
}   
